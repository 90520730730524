.gamfi_v2_hero_btns{
    visibility: unset !important;
}
.sc_banner_v3{
    padding-top:10px;
    padding-bottom:50px;
}
.project_pools_v3{
    padding-top:10px;
}
.tldr{
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1em;
    font-style: italic;
}
p{
    font-size: 1.5em !important;
    font-weight: 400 !important;
    padding-bottom: .25em;
    padding-right: auto;
    margin-bottom: .25em;
    line-height: normal;
    font-family: 'Square', sans-serif !important;
}
ul.content{
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 10px;
    margin-left: 10px;
    list-style-type: disc;
}
ul.content li{
    font-size: 1.5em;
    line-height: 1.5em;
}
li.list4{
    padding-right: 10px;
}

h1 {
    padding-top: 0.5em;
    font-size: 3.5em;
}

/* This will be triggered when the screen width is 600px or less */
@media screen and (max-width: 600px) {
    h1 {
        /* Decrease font size by 40% for mobile */
        font-size: 2.5em; 
    }
}

h2 {
    padding-top: 0.5em;
}
code{
    font-size: medium;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    line-height: normal;
    display: block;
}
.figure{
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
}
.displayquotation{
    display: block;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 1em;
    margin-right: 1em;
    font-style: italic;
}

.displaytext{
    word-break: normal !important;
    word-wrap: normal !important;
}
p a[http] { 
    word-break: break-all;
    word-wrap: break-word;
}
p a[https] { 
    word-break: break-all;
    word-wrap: break-word;
}
