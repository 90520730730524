.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .divider-line {
    flex: 1;
    height: 1px;
    background-color: #ccc;
  }
  
  .divider-icon {
    margin: 0 10px;
    font-size: 20px;
    color: #999;
  }
  
  