.gamfi_v2_hero_btns{
    visibility: unset !important;
}
p{
    font-family: 'Square', sans-serif !important;
    font-weight: normal;
    font-size: 1.5rem;
}
.displaytext{
    word-break: normal !important;
    word-wrap: normal !important;
}